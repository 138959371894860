import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import {useMatomo} from '@datapunt/matomo-tracker-react';

const profileImg = require('../static/images/profile_img.jpg');

/* eslint-disable react/jsx-no-target-blank */
const About: React.FC = (props) => {
  const {trackPageView} = useMatomo();

  // Track page view
  React.useEffect(() => {
    // @ts-ignore
    trackPageView({documentTitle: 'about'});
  });

  return (
    <div className="text">
      <div style={{'display': 'flex'}}>
        <p>
          <Avatar alt="Peter Toth" src={profileImg}
            style={{width: '120px', height: '120px', float: 'left',
              marginRight: '10px', position: 'static'}}/>
          Hi! My name is Péter. Welcome to my blog!
          <br/>
          I&apos;m a software engineer living in Munich, currently working as
          a Backend engineer at <a href="https://www.simscale.com/"
            target="_blank" className="external-link">SimScale</a>. In my
            free time I like to learn new things about Computer Science, mostly
            by participating in coding competitions like&nbsp;
          <a href="https://codingcompetitions.withgoogle.com/codejam"
            target="_blank" className="external-link">Google Code Jam
          </a>&nbsp;or <a href="https://adventofcode.com/" target="_blank"
            className="external-link">Advent of Code</a>. My hobbies also
            include reading, running, hiking and everything related to football.
        </p>
      </div>
      <br/>
      <p>
        I&apos;ve built this website to learn more about web development
        and to post about interesting things I encounter while coding.
      </p>
      <br/>
      <p>
        If you want to get in touch, here is my contact information:
      </p>
      <br/>
      <ul className="contact-list">
        <li className="contact-list-item">
          <div style={{display: 'flex'}}>
            <h3 style={{width: '90px'}}>
              Email
            </h3>
            <p>
              <a href="mailto:me@petertoth.de"
                className="external-link">
                me@petertoth.de
              </a>
            </p>
          </div>
        </li>
        <li className="contact-list-item">
          <div style={{display: 'flex'}}>
            <h3 style={{width: '90px'}}>
              Twitter
            </h3>
            <p>
              <a href="https://twitter.com/pettersson18" target="_blank" className="external-link">@pettersson18</a>
            </p>
          </div>
        </li>
        <li className="contact-list-item">
          <div style={{display: 'flex'}}>
            <h3 style={{width: '90px'}}>
              Linkedin
            </h3>
            <p>
              <a href="https://www.linkedin.com/in/peterrolandtoth/" target="_blank" className="external-link">Peter Toth</a>
            </p>
          </div>
        </li>
        <li className="contact-list-item">
          <div style={{display: 'flex'}}>
            <h3 style={{width: '90px'}}>
              GitHub
            </h3>
            <p>
              <a href="https://github.com/peter-roland-toth/" target="_blank" className="external-link">peter-roland-toth</a>
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default About;
