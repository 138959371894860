import React from 'react';
// @ts-ignore
import {calculate} from '../lib/smallest_polygon.js';

interface CanvasProps {

}

interface Point {
  x: number,
  y: number,
}

interface HullAlgorithmData {
  points: Point[][],
  splitX: number,
  polygons: Point[][],
  type: string,
}

interface CanvasState {
  points: Point[],
  step: number,
  data: HullAlgorithmData[],
}

/**
 * Main App function.
 */
class ConvexHullCanvas extends React.Component<CanvasProps, CanvasState> {
  selector: React.RefObject<SVGSVGElement>;
  /**
  * Main App function.
  * @param{CanvasProps} props
  */
  constructor(props: CanvasProps) {
    super(props);

    this.state = {
      points: [],
      step: 0,
      data: [],
    };

    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.selector = React.createRef();
  }

  /**
  * Main App function.
  */
  componentDidMount() {
    document.getElementById('drawing_area_hull')!
        .addEventListener('mouseup', this.handleMouseUp);
  }

  /**
  * Main App function.
  * @param{MouseEvent} mouseEvent
  */
  handleMouseUp(mouseEvent: MouseEvent) {
    const p = this.relativeCoordinatesForEvent(mouseEvent);
    const newPoints = this.state.points.concat([p]);
    const solution = calculate(JSON.stringify(newPoints));

    this.setState((state, props) => (
      {
        points: newPoints,
        data: JSON.parse(solution),
        step: -1,
      }),
    );
  }

  /**
  * Main App function.
  */
  handleSolve() {
    this.setState((state, _) => (
      {
        step: state.data.length - 1,
      }),
    );
  }

  /**
  * Main App function.
  */
  handleReset() {
    this.setState((state, _) => (
      {
        points: [],
        data: [],
      }),
    );
  }

  /**
  * Main App function.
  */
  handleStep() {
    this.setState((state, props) => (
      {
        step: state.step + 1 < state.data.length ? state.step + 1 : -1,
      }),
    );
  }

  /**
  * Main App function.
  * @param{any} mouseEvent
  * @return{any} Relative coordinates
  */
  relativeCoordinatesForEvent(mouseEvent: MouseEvent) {
    const boundingRect = this.selector.current!.getBoundingClientRect();

    return {
      x: mouseEvent.clientX - boundingRect.left + Math.random() / 100000,
      y: mouseEvent.clientY - boundingRect.top + Math.random() / 100000,
    };
  }

  /**
  * Main App function.
  * @return{any} A div
  */
  render() {
    const d = this.state.data;
    const step = this.state.step;
    const border = 'rgb(0, 35, 33)';
    const leftFill = 'rgb(236, 164, 0)';
    const rightFill = 'rgb(0, 105, 146)';
    const amber = 'rgb(0, 29, 74)';
    const darkRed = 'rgb(112, 58, 17)';

    const verticalLine = () => {
      if (step < d.length && step >= 0) {
        return drawingLine([
          {x: d[step].splitX, y: 0},
          {x: d[step].splitX, y: 800},
        ],
        darkRed,
        '3');
      }
    };

    const polygons = () => {
      if (step < d.length && step >= 0) {
        return d[step].polygons.map((poly) => drawingLine(poly, amber, '1'));
      }
    };

    const description = () => {
      if (step < d.length && step >= 0) {
        let desc = '';
        switch (d[step].type) {
          case 'merge':
            desc = 'Merge';
            break;
          case 'convex_hull':
            desc = 'Convex hull';
            break;
          case 'partition':
            desc = 'Partition';
            break;
          default:
            break;
        }
        return <text x='10' y='20' fontFamily='arial' fontSize='16px'
          fill='black'>
          {desc}
        </text>;
      }
    };

    const highlight = () => {
      if (step < d.length && step >= 0) {
        const minX = Math.min(...d[step].points[0].map((p) => p.x));
        const maxX = Math.max(...d[step].points[1].map((p) => p.x));

        return <rect x={minX - 5} y="0" width={maxX-minX+10} height="500"
          style={{fill: 'blue', fillOpacity: 0.1}} />;
      }
    };

    return <div>
      <svg id="drawing_area_hull" className="drawing_area"
        height="500" width="100%" ref={this.selector}>
        { polygons() }
        { verticalLine() }
        { this.state.points.map((p) => circle(p.x, p.y, border, 'white')) }
        {
          (step < d.length && step >= 0) &&
            d[step].points[0].map((p) => circle(p.x, p.y, border, leftFill))
        }
        {
          (step < d.length && step >= 0) &&
            d[step].points[1].map((p) => circle(p.x, p.y, border, rightFill))
        }
        { highlight() }
        { description() }
      </svg>
      <br/>
      <button className="control-button" onClick={ () => this.handleStep() }>
        STEP
      </button>
      <button className="control-button" onClick={ () => this.handleSolve() }>
        SOLVE
      </button>
      <button className="control-button" onClick={ () => this.handleReset() }>
        RESET
      </button>
    </div>;
  }
}

/**
  * Main App function.
  * @param{any} points
  * @param{any} color
  * @param{any} strokeWidth
  * @return{any} A line
  */
function drawingLine(points: Point[], color: string, strokeWidth: string) {
  if (points.length === 0) {
    return;
  }
  const pathData = 'M ' +
    points
        .map((p) => p.x + ' ' + p.y)
        .join(' L ') +
        ' L ' + points[0].x + ' ' + points[0].y;

  return <path d={pathData} fill='transparent'
    strokeWidth={strokeWidth} stroke={color}/>;
}

/**
  * Main App function.
  * @param{any} x
  * @param{any} y
  * @param{any} stroke
  * @param{any} fill
  * @return{any} A circle
  */
function circle(x: number, y: number, stroke: string, fill: string) {
  return (
    <circle cx={x} cy={y} r="5" stroke={stroke} fill={fill} strokeWidth="2"/>
  );
}

export default ConvexHullCanvas;
