import React, {useState} from 'react';
import {useMatomo} from '@datapunt/matomo-tracker-react';
import ReactMarkdown from 'react-markdown';

// @ts-ignore
import data from '../markdown/aoc-2020.md';

const AdventOfCode2020: React.FC = (props) => {
  const {trackPageView} = useMatomo();
  const [text, setText] = useState('');

  // Track page view
  React.useEffect(() => {
    // @ts-ignore
    trackPageView({documentTitle: 'aoc-2020'});
    fetch(data).then((res) => res.text())
        .then((txt) => setText(txt));
  });

  // @ts-ignore
  const LinkRenderer = (props: ReactComponentElement) => {
    return React.createElement('a', {className: 'external-link',
      href: props.href},
    props.children);
  };

  const LinkTarget = (uri: string, text: string,
      title?: string | undefined) => {
    return '_blank';
  };

  return <ReactMarkdown source={text} escapeHtml={false} className="text"
    renderers={{link: LinkRenderer}}
    linkTarget={LinkTarget}/>;
};

export default AdventOfCode2020;
