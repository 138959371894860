import React from 'react';
import {Link} from 'react-router-dom';

const Header: React.FC = (props) => {
  return (
    <div className="header">
      <div className="header-container">
        <h1>
          Péter Tóth
        </h1>
        <ul className="header-tab-list">
          <li className="header-tab-list-item">
            <Link to="/" className="link">Home</Link>
          </li>
          <li className="header-tab-list-item">
            <Link to="/articles" className="link">Articles</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
