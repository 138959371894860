import React, {useState} from 'react';
import {useMatomo} from '@datapunt/matomo-tracker-react';
import ReactMarkdown from 'react-markdown';
import 'highlight.js/styles/solarized-dark.css';

// @ts-ignore
import data from '../markdown/cses-part-01.md';

const CsesPart01: React.FC = (props) => {
  const {trackPageView} = useMatomo();
  const [text, setText] = useState('');

  // Track page view
  React.useEffect(() => {
    // @ts-ignore
    trackPageView({documentTitle: 'cses-part-01'});
    fetch(data).then((res) => res.text())
        .then((txt) => setText(txt));
  });

  // @ts-ignore
  const LinkRenderer = (props: ReactComponentElement) => {
    return React.createElement('a', {className: 'external-link',
      href: props.href},
    props.children);
  };

  const LinkTarget = (uri: string, text: string,
      title?: string | undefined) => {
    return '_blank';
  };

  // @ts-ignore
  const HighlightRenderer = (props: ReactComponentElement) => {
    const hljs = require('highlight.js');
    return <pre className="cpp-code">
      <code dangerouslySetInnerHTML={{__html:
        hljs.highlight('cpp', props.value).value}} />
    </pre>;
  };

  return <ReactMarkdown source={text} escapeHtml={false} className="text"
    renderers={{link: LinkRenderer, code: HighlightRenderer}}
    linkTarget={LinkTarget}/>;
};

export default CsesPart01;
