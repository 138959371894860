import React from 'react';

/* eslint-disable camelcase */
// @ts-ignore
import {two_finger} from '../lib/smallest_polygon.js';

interface CanvasProps {

}

interface Point {
  x: number,
  y: number,
}

interface HullAlgorithmData {
  points: Point[][],
  splitX: number,
  polygons: Point[][],
  type: string,
}

interface CanvasState {
  leftPoints: Point[],
  rightPoints: Point[],
  step: number,
  data: HullAlgorithmData[],
  bestPoint: Point
}

/**
 * Main App function.
 */
class ConvexHullTwoFingerCanvas extends React.Component
  <CanvasProps, CanvasState> {
  selector: React.RefObject<SVGSVGElement>;
  /**
  * Main App function.
  * @param{CanvasProps} props
  */
  constructor(props: CanvasProps) {
    super(props);

    this.state = {
      leftPoints: [],
      rightPoints: [],
      step: 0,
      data: [],
      bestPoint: {x: 0, y: 0},
    };

    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.selector = React.createRef();
  }

  /**
  * Main App function.
  */
  componentDidMount() {
    document.getElementById('drawing_area')!
        .addEventListener('mouseup', this.handleMouseUp);
  }

  /**
  * Main App function.
  * @param{MouseEvent} mouseEvent
  */
  handleMouseUp(mouseEvent: MouseEvent) {
    const p = this.relativeCoordinatesForEvent(mouseEvent);

    if (p.x >= 155 && p.x <= 165) {
      return;
    }
    if (p.x < 155) {
      this.setState((state, props) => (
        {
          leftPoints: state.leftPoints.concat([p]),
        }),
      );
    } else {
      this.setState((state, props) => (
        {
          rightPoints: state.rightPoints.concat([p]),
        }),
      );
    }

    const ret = JSON.parse(two_finger(JSON.stringify(this.state.leftPoints),
        JSON.stringify(this.state.rightPoints)));
    this.setState((state, props) => (
      {
        data: ret,
      }),
    );
  }

  /**
  * Main App function.
  */
  handleStep() {
    const nextStep = this.state.step + 1 < this.state.data.length ?
      this.state.step + 1 : 0;
    let newBest = this.state.bestPoint;
    if (nextStep === 0) {
      newBest = {x: 0, y: 0};
    } else if (this.state.step > 0 &&
      this.state.step < this.state.data.length) {
      if (this.state.data[this.state.step].polygons[0][0].y > newBest.y) {
        newBest = this.state.data[this.state.step].polygons[0][0];
      }
    }
    this.setState((state, props) => (
      {
        step: nextStep,
        bestPoint: newBest,
      }),
    );
  }

  /**
  * Main App function.
  */
  handleReset() {
    this.setState((state, props) => (
      {
        leftPoints: [],
        rightPoints: [],
        step: 0,
        data: [],
        bestPoint: {x: 0, y: 0},
      }),
    );
  }

  /**
  * Main App function.
  * @param{any} mouseEvent
  * @return{any} Relative coordinates
  */
  relativeCoordinatesForEvent(mouseEvent: MouseEvent) {
    const boundingRect = this.selector.current!.getBoundingClientRect();

    return {
      x: mouseEvent.clientX - boundingRect.left,
      y: mouseEvent.clientY - boundingRect.top,
    };
  }

  /**
  * Main App function.
  * @return{any} A div
  */
  render() {
    const d = this.state.data;
    const step = this.state.step;
    const bp = this.state.bestPoint;
    const c1 = 'rgb(0, 35, 33)';
    const leftFill = 'rgb(236, 164, 0)';
    const rightFill = 'rgb(0, 105, 146)';
    const amber = 'rgb(166, 103, 54)';
    const darkRed = 'rgb(112, 58, 17)';
    const width = 350;

    const verticalLine = () => {
      if (step < d.length && step > 0) {
        return drawingLine([
          {x: d[step].polygons[0][0].x, y: 0},
          {x: d[step].polygons[0][0].x, y: 800},
        ],
        darkRed,
        '3');
      }
    };

    const bestPoint = () => {
      if (bp.x === 0 && bp.y === 0) {
        return;
      }

      return circle(
          bp.x,
          bp.y,
          'black',
          'red',
      );
    };

    const currentPoint = () => {
      if (step < d.length && step > 0) {
        return circle(
            d[step].polygons[0][0].x,
            d[step].polygons[0][0].y,
            'gray',
            'white',
        );
      }
    };

    const crossingLine = () => {
      if (step < d.length && step > 0) {
        return drawingLine([
          {x: d[step].points[0][0].x, y: d[step].points[0][0].y},
          {x: d[step].points[1][0].x, y: d[step].points[1][0].y},
        ],
        'blue',
        '3');
      }
    };

    const middleSplitLine = () => {
      return drawingLine([
        {x: width/2, y: 0},
        {x: width/2, y: 800},
      ],
      'lightgray',
      '10');
    };

    const polygons = () => {
      if (d.length > 0) {
        if (d[0].type === 'polygon') {
          return d[0].polygons.map((poly) => drawingLine(poly, amber, '1'));
        }
      }
    };

    const description = () => {
      if (step < d.length) {
        let desc = '';
        if (d[step].type === 'check') {
          desc = 'Checking ' + d[step].polygons[0][0].y.toFixed(3) +
            ' - current highest y: ' + bp.y.toFixed(3);
        } else if (d[step].type === 'best') {
          desc = 'Current highest y: ' + d[step].polygons[0][0].y.toFixed(3);
        } else if (d[step].type === 'final') {
          desc = 'Highest y: ' + bp.y.toFixed(3);
        }
        return <text x='10' y='20' fontFamily='arial' fontSize='16px'
          fill='black'>
          {desc}
        </text>;
      }
    };

    return <div>
      <svg id="drawing_area" className="drawing_area"
        height="300" width={width} ref={this.selector}>
        { middleSplitLine() }
        { polygons() }
        { verticalLine() }
        { crossingLine() }
        { this.state.leftPoints.map((p) => circle(p.x, p.y, c1, leftFill)) }
        { this.state.rightPoints.map((p) => circle(p.x, p.y, c1, rightFill)) }
        { description() }
        { currentPoint() }
        { bestPoint() }
      </svg>
      <br/>
      <button className="control-button" onClick={ () => this.handleStep() }>
        STEP
      </button>
      <button className="control-button" onClick={ () => this.handleReset() }>
        RESET
      </button>
    </div>;
  }
}

/**
  * Main App function.
  * @param{any} points
  * @param{any} color
  * @param{any} strokeWidth
  * @return{any} A line
  */
function drawingLine(points: Point[], color: string, strokeWidth: string) {
  if (points.length === 0) {
    return;
  }
  const pathData = 'M ' +
    points
        .map((p) => p.x + ' ' + p.y)
        .join(' L ') +
        ' L ' + points[0].x + ' ' + points[0].y;

  return <path d={pathData} fill='transparent'
    strokeWidth={strokeWidth} stroke={color}/>;
}

/**
  * Main App function.
  * @param{any} x
  * @param{any} y
  * @param{any} stroke
  * @param{any} fill
  * @return{any} A circle
  */
function circle(x: number, y: number, stroke: string, fill: string) {
  return (
    <circle cx={x} cy={y} r="5" stroke={stroke} fill={fill} strokeWidth="2"/>
  );
}

export default ConvexHullTwoFingerCanvas;
