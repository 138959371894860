import React from 'react';
import './stylesheets/main.css';
import About from './components/About';
import Articles from './components/Articles';
import Header from './components/Header';
import {BrowserRouter as Router,
  Switch,
  Route} from 'react-router-dom';
import ConvexHull from './articles/ConvexHull';
import ConvexHullPart2 from './articles/ConvexHullPart2';
import AdventOfCode2020 from './articles/AdventOfCode2020';
import CsesPart01 from './articles/CsesPart01';
import CsesPart02 from './articles/CsesPart02';

/**
 * Main App function.
 * @return {Router} The App
 */
function App() {
  return (
    <Router>
      <div>
        <Header />
        <div className="main-container">
          <Switch>
            <Route exact path="/">
              <About />
            </Route>
            <Route path="/articles/convex-hull">
              <ConvexHull />
            </Route>
            <Route path="/articles/convex-hull-part-2">
              <ConvexHullPart2 />
            </Route>
            <Route path="/articles/advent-of-code-2020">
              <AdventOfCode2020 />
            </Route>
            <Route path="/articles/cses-part-1">
              <CsesPart01 />
            </Route>
            <Route path="/articles/cses-part-2">
              <CsesPart02 />
            </Route>
            <Route path="/articles">
              <Articles />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
