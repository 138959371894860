import React, {useState} from 'react';
import ConvexHullCanvas from '../components/ConvexHullCanvas';
import ConvexHullTwoFingerCanvas from '../components/ConvexHullTwoFingerCanvas';
import {useMatomo} from '@datapunt/matomo-tracker-react';
import ReactMarkdown from 'react-markdown';
// @ts-ignore
import htmlParser from 'react-markdown/plugins/html-parser';
// @ts-ignore
import data from '../markdown/convex-hull.md';

const ConvexHull: React.FC = (props) => {
  const {trackPageView} = useMatomo();
  const [text, setText] = useState('');

  // Track page view
  React.useEffect(() => {
    // @ts-ignore
    trackPageView({documentTitle: 'convex-hull'});
    fetch(data).then((res) => res.text())
        .then((txt) => setText(txt));
  });

  const processingInstructions = [
    {
      // @ts-ignore
      shouldProcessNode: function(node) {
        return node.name === 'convexhulltwofingercanvas' ||
            node.name === 'convexhullcanvas';
      },
      // @ts-ignore
      processNode: function(node, children) {
        if (node.name === 'convexhulltwofingercanvas') {
          return <ConvexHullTwoFingerCanvas/>;
        } else if (node.name === 'convexhullcanvas') {
          return <ConvexHullCanvas/>;
        }
      },
    },
  ];
  const parseHtml = htmlParser({
    // @ts-ignore
    isValidNode: (node) => node.type !== 'script',
    processingInstructions: processingInstructions,
  });

  // @ts-ignore
  const flatten = (text: string, child) => {
    return typeof child === 'string' ? text + child :
    React.Children.toArray(child.props.children).reduce(flatten, text);
  };

  // @ts-ignore
  const HeadingRenderer = (props: ReactComponentElement) => {
    const children = React.Children.toArray(props.children);
    const text = children.reduce(flatten, '');
    const slug = text.toLowerCase().replace(/\W/g, '-');
    return React.createElement('h' + props.level, {id: slug}, props.children);
  };

  // @ts-ignore
  const LinkRenderer = (props: ReactComponentElement) => {
    return React.createElement('a', {className: 'external-link',
      href: props.href},
    props.children);
  };

  const LinkTarget = (uri: string, text: string,
      title?: string | undefined) => {
    return '_blank';
  };

  return <ReactMarkdown source={text} escapeHtml={false}
    astPlugins={[parseHtml]} className="text"
    renderers={{heading: HeadingRenderer, link: LinkRenderer}}
    linkTarget={LinkTarget}/>;
};

export default ConvexHull;
