import React from 'react';
import {Link} from 'react-router-dom';
import {useMatomo} from '@datapunt/matomo-tracker-react';

const Articles: React.FC = (props) => {
  const {trackPageView} = useMatomo();

  React.useEffect(() => {
    // @ts-ignore
    trackPageView({documentTitle: 'articles'});
  });

  return (
    <div className="text">
      <h3>
        Here is the list of my articles about programming and not only
      </h3>
      <br/>
      <ul>
        <h2>
          September 2021
        </h2>
        <br/>
        <ul>
          <li>
            <Link to="/articles/cses-part-2" className="link">
              CSES problem set - Part 2
            </Link>
          </li>
        </ul>
        <br/>
        <h2>
          July 2021
        </h2>
        <br/>
        <ul>
          <li>
            <Link to="/articles/cses-part-1" className="link">
              CSES problem set - Part 1
            </Link>
          </li>
        </ul>
        <br/>
        <h2>
          November 2020
        </h2>
        <br/>
        <ul>
          <li>
            <Link to="/articles/advent-of-code-2020" className="link">
              Advent of Code 2020
            </Link>
          </li>
        </ul>
        <br/>
        <h2>
          September 2020
        </h2>
        <br/>
        <ul>
          <li>
            <Link to="/articles/convex-hull-part-2" className="link">
              Convex hull implementation - Part 2
            </Link>
          </li>
        </ul>
        <br/>
        <h2>
          July 2020
        </h2>
        <br/>
        <ul>
          <li>
            <Link to="/articles/convex-hull" className="link">
              Convex hull implementation
            </Link>
          </li>
        </ul>
      </ul>
    </div>
  );
};

export default Articles;
