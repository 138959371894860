import React, {useState} from 'react';
import ConvexHullCanvas from '../components/ConvexHullCanvas';
import ConvexHullTwoFingerCanvas from '../components/ConvexHullTwoFingerCanvas';
import {useMatomo} from '@datapunt/matomo-tracker-react';
import ReactMarkdown from 'react-markdown';
// @ts-ignore
import htmlParser from 'react-markdown/plugins/html-parser';
// @ts-ignore
import data from '../markdown/convex-hull-part-2.md';
import hljs from 'highlight.js';
import 'highlight.js/scss/monokai-sublime.scss';

const ConvexHullPart2: React.FC = (props) => {
  const {trackPageView} = useMatomo();
  const [text, setText] = useState('');

  // Track page view
  React.useEffect(() => {
    // @ts-ignore
    trackPageView({documentTitle: 'convex-hull-part-2'});
    fetch(data).then((res) => res.text())
        .then((txt) => setText(txt));
  });

  const processingInstructions = [
    {
      // @ts-ignore
      shouldProcessNode: function(node) {
        return node.name === 'convexhulltwofingercanvas' ||
            node.name === 'convexhullcanvas' ||
            node.name === 'img';
      },
      // @ts-ignore
      processNode: function(node, children) {
        console.log(node);
        if (node.name === 'convexhulltwofingercanvas') {
          return <ConvexHullTwoFingerCanvas/>;
        } else if (node.name === 'convexhullcanvas') {
          return <ConvexHullCanvas/>;
        } else if (node.name === 'img') {
          const name = node.attribs['src'];
          const alt = node.attribs['alt'];
          const src = require('../static/images/' + name);
          return <img src={src} alt={alt} style={{maxWidth: '40%',
            height: 'auto', display: 'block', marginLeft: 'auto',
            marginRight: 'auto'}}/>;
        }
      },
    },
  ];
  const parseHtml = htmlParser({
    // @ts-ignore
    isValidNode: (node) => node.type !== 'script',
    processingInstructions: processingInstructions,
  });

  // @ts-ignore
  const flatten = (text: string, child) => {
    return typeof child === 'string' ? text + child :
    React.Children.toArray(child.props.children).reduce(flatten, text);
  };

  // @ts-ignore
  const HeadingRenderer = (props: ReactComponentElement) => {
    const children = React.Children.toArray(props.children);
    const text = children.reduce(flatten, '');
    const slug = text.toLowerCase().replace(/\W/g, '-');
    return React.createElement('h' + props.level, {id: slug}, props.children);
  };

  // @ts-ignore
  const LinkRenderer = (props: ReactComponentElement) => {
    return React.createElement('a', {className: 'external-link',
      href: props.href},
    props.children);
  };

  const LinkTarget = (uri: string, text: string,
      title?: string | undefined) => {
    return '_blank';
  };

  // @ts-ignore
  const HighlightRenderer = (props: ReactComponentElement) => {
    return <pre className="go-code">
      <code dangerouslySetInnerHTML={{__html:
        hljs.highlight('go', props.value).value}} />
    </pre>;
  };

  return <ReactMarkdown source={text} escapeHtml={false}
    astPlugins={[parseHtml]} className="text"
    renderers={{heading: HeadingRenderer, link: LinkRenderer,
      code: HighlightRenderer}}
    linkTarget={LinkTarget}/>;
};

export default ConvexHullPart2;
