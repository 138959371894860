import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {MatomoProvider, createInstance} from '@datapunt/matomo-tracker-react';

let siteId = 2;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  siteId = 1;
}

const instance = createInstance({
  urlBase: 'https://petertoth.de:444/',
  siteId: siteId,
});

ReactDOM.render(
    <React.StrictMode>
      <MatomoProvider value={instance}>
        <App />
      </MatomoProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
